<template>
  <div>
    <div class="filter-box">
      <ul class="filter-ul">
        <select-picker :data="filterData.oppoLevel" />
        <select-picker :data="filterData.trackReliable" />
        <select-picker
          v-if="$route.params.i !== 0"
          :data="filterData.sellerUserId"
          @toTree="toTree"
        />
        <select-picker
          v-if="$route.params.i !== 0"
          :data="filterData.orgId"
          @toTree="toTree"
        />
        <select-picker
          v-if="$route.params.i !== 0"
          :data="filterData.branchOrgId"
        />
      </ul>
    </div>
    <div class="bottom-postion">
      <cube-button
        :outline="true"
        class="btn btns"
        @click="reset"
      >
        重置
      </cube-button>
      <cube-button
        class="btn btns"
        @click="confirm"
      >
        确认
      </cube-button>
    </div>
  </div>
</template>

<script>
import selectPicker from '_c/picker/select';
import { selectOrgByOrgType } from '_api/global';
import { regular } from '@/lib/until';
import { mapMutations, mapGetters } from 'vuex';

export default {
  components: {
    selectPicker
  },
  data() {
    return {
      filterData: {
        oppoLevel: {
          title: '商机等级',
          name: '',
          value: '',
          options: []
        },
        trackReliable: {
          title: '进程推进',
          name: '',
          value: '',
          options: []
        },
        sellerUserId: {
          title: '销售',
          name: '',
          value: '',
          dap: false,
          typeName: 'sellerUserId'
        },
        orgId: {
          title: '所属部门',
          name: '',
          value: '',
          dap: false,
          typeName: 'orgId'
        },
        branchOrgId: {
          title: '分公司',
          name: '',
          value: '',
          options: []
        }
      }
    };
  },
  computed: {
    ...mapGetters('business', ['filterObj']),
    ...mapGetters(['dictList'])
  },
  mounted() {
    this.getSellData();
    // 获取分公司
    this.getRegionFiliales(2);
    this.getOptions();
  },
  methods: {
    ...mapMutations('business', ['BUS_FILTER']),
    ...mapMutations(['CLEAR_FILTER']),
    confirm() {
      this.cache();
      this.$router.go(-1);
    },
    reset() {
      for (const i in this.filterData) {
        this.filterData[i].name = '';
        this.filterData[i].value = '';
      }
      this.CLEAR_FILTER('business');
    },
    cache() {
      const filterData = {};
      for (const i in this.filterData) {
        filterData[i] = {
          name: this.filterData[i].name,
          value: this.filterData[i].value
        };
      }

      this.BUS_FILTER(filterData);
    },
    toTree(typeName) {
      this.cache();
      this.$router.push(
        {
          name: 'tree',
          params: {
            routerName: this.$route.name,
            typeName
          }
        },
      );
    },
    getSellData() {
      if (JSON.stringify(this.filterObj) !== '{}') {
        for (const i in this.filterObj) {
          this.filterData[i].name = this.filterObj[i] ? this.filterObj[i].name : '';
          this.filterData[i].value = this.filterObj[i] ? this.filterObj[i].value : '';
        }
      }
    },
    getOptions() {
      this.filterData.oppoLevel.options = this.dictList.oppoLevel;
      this.filterData.trackReliable.options = this.dictList.reliable;
    },
    getRegionFiliales(orgType) {
      selectOrgByOrgType(orgType).then(res => {
        if (res.flag) {
          let list = res.data;
          list.forEach(item => {
            this.filterData.branchOrgId.options.push(
              {
                text: regular(item.key),
                value: item.id
              }
            );
          });
        } else {
          this.$showToast(res.errorMsg);
        }
      });
    }
  }
};

</script>

<style lang="scss" scoped  src="@/assets/css/components/btn-bottom.scss"></style>
<style lang="scss" scoped  src="@/assets/css/components/filter.scss"></style>
